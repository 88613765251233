/* src/components/BottomBar.css */
.bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding-top: 12px; /* Separate top padding */
  padding-bottom: 30px; /* Increase bottom padding */
  z-index: 1000; /* Ensure the bottom bar is above other content */
}

.bottom-bar-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bottom-bar-button:hover .icon {
  color: #797979;
}

.icon {
  color: rgb(166, 166, 166);
  font-size: 23px; /* Increase font size to make the icons larger */
}

.bottom-bar-button.active .icon {
  color: #000000;
}