/* src/components/PersistentLayout.css */
.persistent-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevent horizontal scrolling */
}

.content {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px;
  padding-bottom: 100px; /* Increase padding to account for BottomBar */
}